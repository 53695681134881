import React, {useEffect, useState} from 'react';
import {Link,useLocation, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';

import Globals from '../Globals';

export default function BlogPost() {
    const {slug} = useParams()
    console.log(slug)
    var WPAPI = require( 'wpapi' );
    const [posts,setPosts] = useState([]);
    useEffect(() => {
        async function loadPosts() {

            var wp = new WPAPI({
                endpoint: Globals.WP_API_URL,
            });
            wp.posts().get(function( err, posts ) {

                let post=posts[Object.keys(posts).find((post, i) => {
                    return posts[post].slug === slug;
                })] || {};
                setPosts(post);
            });
        }

        loadPosts();
    }, [])
    return (
        <>
            <Helmet>
                <title>About - igChamp</title>
                <meta name="description"
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta property="og:title" content="About - igChamp"/>
                <meta property="Learn about igChamp stories, mission and our vision."
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta name="keywords" content=""/>
                <meta property="og:image" content="#"/>
                <meta property="og:url" content="/about"/>
                <link rel="canonical" href="https://igchamp.com/about"/>
            </Helmet>
            <Navbar/>

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-10">
                        {posts.id &&
                            <div>
                                <h1 className="text-3xl leading-normal font-semibold" > {posts.title.rendered}</h1>
                                <img src={posts.featured_image_src}/>
                                <div dangerouslySetInnerHTML={{__html: posts.content.rendered}}/>
                            </div>
                        }

                    </div>
                </div>

            </section>





            <Footer/>
        </>
    )
}
