import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Navbar from '../component/Navbar/navbar'
import Footer from '../component/Footer/footer';

import {MdKeyboardArrowRight, FaArrowRight, MdOutlineKeyboardArrowLeft, BsCheckCircle, FaArrowDown} from "../assets/icons/icons"

import {accordionData, consultingAbout, igFollowersFaq, pricingTableData, socialPresence} from '../data/dataTwo';
import shree_logo from "../assets/images/igchamp/our-mission.png";
import google_logo from "../assets/images/igchamp/our-vision.png";
import lenovo_logo from "../assets/images/igchamp/why-choose.png";
import circle_logo from "../assets/images/igchamp/individuals.png";
import classic02 from "../assets/images/igchamp/why-choose.png";
import Globals from '../Globals';

export default function Blog() {

    var WPAPI = require( 'wpapi' );
    const [posts,setPosts] = useState([]);

    //console.log(postsActions.getPosts());
    //let posts1=this.props.postsActions.getPosts();
    /*const [activeIndex, setActiveIndex] = useState(0);
    var wp = new WPAPI({
        endpoint: Globals.WP_API_URL,
    });*/
    /*wp.posts().get(function( err, data ) {
        setPosts(data);
    });*/

    //const [posts, setPosts] = useState([]);
    useEffect(() => {
        async function loadPosts() {

            var wp = new WPAPI({
                endpoint: Globals.WP_API_URL,
            });

            wp.posts().get(function( err, data ) {

                setPosts(data);
            });
            /*const response = await fetch('/wp-json/wp/v2/posts');
            if(!response.ok) {
                // oups! something went wrong
                return;
            }*/

            //const posts = await response.json();
            //setPosts(posts);
        }

        loadPosts();
    }, [])
    return (
        <>
            <Helmet>
                <title>Blog - igChamp</title>
                <meta name="description"
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta property="og:title" content="Blog - IGChamp"/>
                <meta property="Learn about igChamp stories, mission and our vision."
                      content="Learn about Igcmap stories, mission and our vision."/>
                <meta name="keywords" content=""/>
                <meta property="og:image" content="#"/>
                <meta property="og:url" content="/blog"/>
                <link rel="canonical" href="https://igchamp.com/blog"/>
            </Helmet>
            <Navbar/>

            <section
                className="relative table w-full py-32 lg:py-36 bg-gray-50 dark:bg-slate-800">
                <div className="absolute inset-0 "></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">Blogs</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px]  mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
                            <Link to="/index">IGChamp</Link></li>
                        <li className="inline-block text-base  mx-0.5 ltr:rotate-0 rtl:rotate-180">
                            <MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"
                            aria-current="page">Blogs
                        </li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        {posts.map((post, index) => {
                            return (
                                <div key={index}
                                     className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <img src={post.featured_image_src} alt=""/>

                                    <div className="content p-6">
                                        <Link to={`/blog/${post.slug}`}
                                              className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out" dangerouslySetInnerHTML={{__html: post.title.rendered}}></Link>
                                        <p className="text-slate-400 mt-3" dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}/>

                                        <div className="mt-4">
                                            <Link to={`/blog/${post.slug}`}
                                                  className="relative inline-flex font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read
                                                More <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>



            </section>




            <Footer/>
        </>
    )
}
